/* COLORS */
$branco-fumaca: #f5f5f5;
$cinza-claro: #eee;
$azul-bebe: #104E8B;
$azul-celeste: #87CEFA;
$azul-claro: #cce6ff;
$azul-celeste-escuro: #1E90FF;
$azul-perola: #3e83f1;
$cinza-escuro: #777777;
$error-text-color: #B94A48;
$semi-transparente: rgba(255, 255, 255, 0.9);
$semi-opaco: rgba(0, 0, 0, 0.7);
$opaco: rgba(0, 0, 0, 0.9);

$radius: 6px;
$sh1: 1px;
$sh2: 2px;
$rgba: rgba(0,0,0,.15);

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
  #sidebar {
    margin-left: -250px;
  }
  #sidebar.active {
    margin-left: 0;
  }
  #sidebarCollapse span {
    display: none;
  }
}

/* FIELDSET */

fieldset.fsStyle {
  font-family: Verdana, Arial, sans-serif;
  font-size: small;
  font-weight: normal;
  border: 1px solid #e0ebeb;
  padding: 15px;
  margin: 1% 0;
}

legend.legendStyle {
  font-size: 90%;
  color: #888888;
  background-color: transparent;
  font-weight: bold;
  margin-bottom: 0;
}

legend {
  width: auto;
  border-bottom: 0px;
}

figure {
  width: 100%;
  text-align: center;
  font-style: italic;
  font-size: smaller;
  text-indent: 0;
  border: thin silver solid;
  margin: 0.5em;
  padding: 0.5em;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
  width: 100%;
  padding: 10px;
  min-height: 100vh;
  transition: all 0.3s;
}

body {
  font-family: 'Poppins', sans-serif;
  background: #fafafa;
}

p {
  font-family: 'Poppins', sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.1em;
  color: rgb(33, 37, 41);
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

a.disabled {
  color: gray;
}

a.retro-link {
  color: #51aad8 !important;
  text-decoration: underline !important;
}

.navbar {
  padding: 5px;
  background: #fff;
  border: none;
  border-radius: 0;
  margin-bottom: 10px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}

.login-box {
  margin-top: 100px;
}

.modal {
  overflow-y: auto;
}

.modal-body {
  padding: 35px;
}

.table-responsive {
  margin-top: 10px;
}

.nested-fields {
  margin-bottom: 10px;
}

.brand {
  color: #0080ff;
}

.footer {
  width: 100%;
  text-align: center;
}

.horario-livre {
  background-color: white;
  color: black;
}

.horario-agendado {
  background-color: #ff8080;
  color: black;
}

.total-pgto, .total {
  font-size: 15px;
  font-weight: bold;
  span {
    color: #88cc00;
  }
}

.space-button {
  margin-top: 22px;
}

p.total, p.total-produto {
  span {
    color: green;
    font-weight: bold;
  }
}

.link-new-resource {
  font-size: 14px;
  color: $azul-celeste-escuro;
  transition: all 0.3s;
}

.new-fornecedor, .new-categoria, .new-despesa {
  display: none;
}

.mybox {
  width: 350px !important;
}

.mybox-middle {
  width: 250px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}
.multi-dias {
  display: none;
}

/* CALENDAR */
.consulta-name {
  font-weight: bold;
  line-height: 100%;
}

.card-consulta-calendar {
  padding: 1%;
  background-color: $azul-claro;
}

.fc-center {
  color: red;
  h2 {
    font-weight: bold;
  }
}

.fc-consulta-desmarc {
  background-color: black;
  border-color: black;
}

/* LANCAMENTO */

.lancamento-pago {
  background-color: #80ff80;
}

.lancamento-vencido {
  background-color: #ff8080;
}

.lancamento-a-vencer {
  background-color: white;
}

.lancamento-row-default {
  background-color: white;
}

.lancamento-row-over {
  background-color: $azul-celeste;
}

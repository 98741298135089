/* POPOVER */

.popover {
  /*background-color: #bbb;*/
  width: 500px;
}

.qtip-content  {
  p {
    text-align: center;
  }

  p.qtip-event-date {
    font-weight: bold;
    font-size: 16px;
  }

  .qtip-event-content {
    text-align: center;
    font-size: 15px;
  }
}

.qtip-event-content-associado {
  line-height: 15px;
  font-weight: bold;
}

.splash {
  position: fixed;
  top: 40%;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 3000;
}

.splash img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 100px;
  width: 100px;
}

.card-splash {
  background-color: #f7f7f7;
  padding: 20px 25px 15px;
  margin: 0 auto 25px;
  width: 380px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.mybar {
  background: #29d;
  height:2px;
}

.progress {
  height: 2px;
}

.spinner-icon {
  position: absolute;
  right: 20px;
}

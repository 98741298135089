svg#odontograma {
  pointer-events: none;

  image, text {
    pointer-events: none;
  }

  #odontograma-layer-1 {
    pointer-events: none !important;
  }

  * {
    pointer-events: all;
  }
}